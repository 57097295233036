import http from '../utils/request.js'

const status = {

    getProductionStatus(limit, start, id) {
        if (!id) id = 1
        const params = {
            workshop_id: id
        }
        return http.post(`/screen/productionBoard`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
    },
    getNewProductionStatus(id) {
        if (!id) id = 1
        const params = {
            workshop_id: id
        }
        return http.post(`/screen/newProductionBoard`, params, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
    }
}

export default status;
