<template>
  <div class="status" ref="appRef">
    <div class="bg">
      <MyHeader :title="title"></MyHeader>
      <div class="status-container">
        <dv-border-box12>
          <div class="select-container">
            <el-select
                v-model="workshop_value"
                placeholder="车间编号"
                @change="handleWorkshopSelect"
                style="padding: 10px;"
            >
              <el-option
                  v-for="item in workshop_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
                v-model="production_line_value"
                placeholder="产线编号"
                @change="handleTaskSelect"
            >
              <el-option
                  v-for="item in production_line_options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="grid-container">
            <div
                v-for="(items, rowIndex) in rows"
                class="grid-row"
                :key="rowIndex"
            >
              <div
                  v-for="(item, colIndex) in items"
                  class="grid-item"
                  :key="colIndex"
              >
                <div class="top-bar">
                    <span>{{ item.machine_name }}</span>
                    <span style="padding-left: 25px;">
                        状态: {{ getStatusName(item.machine_status) }}{{ calculateTimeDifference(item.time) }}
                    </span>
                </div>
                <div class="grid-content">
                    <div class="top" style="display: flex;">
                        <div class="left" style="padding-left: 10px;">
                            <el-table 
                                :data="[{p: getSheet_1_1(item.machine_type) , x: item.x_cur, y: item.y_cur, z: item.z_cur},
                                        {p: '机械坐标', x: item.x_mach, y: item.y_mach, z: item.z_mach},
                                        {p: getSheet_1_3(item.machine_type), x: item.x_work, y: item.y_work, z: item.z_work},
                                        {p: '剩余距离', x: item.x_res, y: item.y_res, z: item.z_res}
                                ]" 
                                style="font-size: 10px;">
                                <el-table-column prop="p" label=" " width="106" align="center" />
                                <el-table-column prop="x" label="X" width="106" align="center" />
                                <el-table-column prop="y" label="Y" width="106" align="center" />
                                <el-table-column prop="z" label="Z" width="106" align="center" />
                            </el-table>
                        </div>
                        <div class="right" style="padding-left:20px ;">
                            <vue-awesome-progress
                                :key="item.machine_status"
                                :circle-color="item.color"
                                :circle-radius="60"
                                :circle-width="3"
                                :line-width="9"
                                :line-color="getStatusColor(item.machine_status)"
                                :font-size="24"
                                :font-color="getStatusColor(item.machine_status)"
                                :point-radius="0"
                                :duration="1.5"
                                :start-deg="0"
                                :percentage= "(item.percent*100 .toFixed(1)==NaN||item.percent==0)?0:item.percent*100 .toFixed(1)"
                                :show-text="true"
                                easing="0,0,1,1"
                                style="margin-top: 10px;"
                            />
                        </div>
                    </div>
                    <div class="bottom" style="padding-top: 3px;padding-left: 5px;display: flex;">
                        <div class="left" style="flex: 1;">
                            <div class="info">
                                <div class="key"><p>操作员：{{ item.worker_name }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>工序：{{ item.procrdure_name }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>程序名：{{ item.prog_name }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>系统型号：{{ item.machine_type }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>操作模式：{{ item.mode }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>当前刀具号：{{ item.tool_num }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>工件数：{{ item.products }}</p></div>
                                <div class="value"></div>
                            </div>
                        </div>
                        <div class="mid" style="flex: 1;">
                            <div class="info">
                                <div class="key"><p>主轴倍率：{{ item.spindle_override }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>主轴负载：{{ item.spindle_load }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>主轴速度：{{ item.spindle_speed }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>主轴速度设定值：{{ item.spindle_speed_set }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>进给速度：{{ item.feed_rate }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>进给倍率：{{ item.feed_override }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>进给速度设定值：{{ item.feed_rate_set }}</p></div>
                                <div class="value"></div>
                            </div>
                        </div>
                        <div class="right" style="flex: 1;">
                            <div class="info">
                                <div class="key"><p>{{getRight_1(item.machine_type)}}：{{ item.run_time }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>加工时间：{{ item.cut_time }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>{{getRight_3(item.machine_type)}}：{{ item.cycle_time }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>上电时间：{{ item.power_on_time }}</p></div>
                                <div class="value"></div>
                            </div>
                            <div class="info">
                                <div class="key"><p>告警个数：{{ item.alm_count }}</p></div>
                                <div class="value"></div>
                            </div>
                        </div>
                    </div>
                  <!-- <div :class="getTopBarStatusClass(item.machine_status)">
                    <div class="desc" v-if="item.procrdure_name">
                      <i class="iconfont icon-hanjiejiangongxuxianluka"></i>
                      <span>{{ item.procrdure_name }}</span>
                    </div>
                    <div class="desc" v-if="item.worker_name">
                      <i class="iconfont icon-gongren"></i>
                      <span>{{ item.worker_name }}</span>
                    </div>
                    <div class="desc" v-if="item.task_id">
                      <i class="iconfont icon-renwu"></i>
                      <span>Task{{ item.task_id }}</span>
                    </div>
                  </div> -->
                  <!-- <div :class="getPercentageStatusClass(item.machine_status)">
                    <div class="percent_chart">
                      <vue-awesome-progress
                          :key="item.machine_status"
                          :circle-color="item.color"
                          :circle-radius="60"
                          :circle-width="3"
                          :line-width="9"
                          :line-color="item.color"
                          :font-size="24"
                          :font-color="item.color"
                          :point-radius="0"
                          :duration="1.5"
                          :start-deg="0"
                          :percentage= "(item.percent*100 .toFixed(1)==NaN||item.percent==0)?0:item.percent*100 .toFixed(1)"
                          :show-text="true"
                          easing="0,0,1,1"
                          style="margin-top: 10px;"
                      />
                    </div>
                    <span
                    >{{
                        getStatusName(item.machine_status)
                      }}{{ calculateTimeDifference(item.time) }}</span
                    >
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="block">
            <el-pagination
                :hide-on-single-page="true"
                layout="prev, pager, next"
                :total="total_page"
                :page-size="this.page_size"
                @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </dv-border-box12>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import MyHeader from "../../components/header/myHeader.vue";
import drawMixin from "@/utils/drawMixin";
// import mock from "@/api/mock";
import status from "@/api/status";
import VueAwesomeProgress from "vue-awesome-progress";

export default defineComponent({
  name: "status",
  mixins: [drawMixin],
  data() {
    return {
      title: "生产状态看板",
      statusData: [],
      dataStatusBackup: [],
      totalItems: null,
      itemsPerRow: 3,
      offStatus: "关机",
      listLoading: false,
      workshop_value: "",
      production_line_options: [],
      production_line_value: "",
      total_page: 0,
      page_size: 6,
      current_page: 1,
      tempValue: -1,
      tempStatusData:[],
      workshop_options: [
        {
          value: "1",
          label: "ws-001",
        },
        {
          value: "2",
          label: "ws-002",
        },
        {
          value: "3",
          label: "ws-003",
        },
        {
          value: "4",
          label: "ws-004",
        },
        {
          value: "5",
          label: "ws-005",
        },
      ],
    };
  },
  components: {
    MyHeader,
    VueAwesomeProgress,
  },
  mounted() {
    // setInterval(() => {
    //   this.fetchData();
    // }, 5000);
  },
  created() {
    this.fetchData();
  },
  computed: {
    rows() {
      this.totalItems = this.tempStatusData.length;
      const rows = [];
      let remainingItems = this.totalItems;
      while (remainingItems > this.itemsPerRow) {
        rows.push(
            this.tempStatusData.slice(
                rows.length * this.itemsPerRow,
                rows.length * this.itemsPerRow + this.itemsPerRow
            )
        );
        remainingItems -= this.itemsPerRow;
      }
      if (remainingItems > 0)
        rows.push(
            this.tempStatusData.slice(
                rows.length * this.itemsPerRow,
                rows.length * this.itemsPerRow + remainingItems
            )
        );
      return rows;
    },
  },
  methods: {
    handleWorkshopSelect() {
      this.tempStatusData = [];
      this.statusData = [];
      this.dataStatusBackup = [];
      this.total_page = 0;
      this.current_page = 1;
      this.fetchData();
      this.tempValue = -1
      this.production_line_value = "";
    },
    handleTaskSelect(val) {
      console.log(val, this.production_line_value)
      this.current_page = 1;
      this.tempValue = val
      this.fetchData();
    },
    getStatusName(status) {
      // 根据不同的 status 返回对应的类名
      switch (status) {
        case 1:  return "运行";
        case 2:  return "等待";
        case 3:  return "离线";
        case 4:  return "设备异常";
        case 5:  return "电气故障";
        case 6:  return "程序出错";
        case 7:  return "控制故障";
        case 8:  return "操作失误";
        case 9:  return "机械磨损";
        case 10: return "电器维修";
        case 11: return "调整程序";
        case 12: return "控制维修";
        case 13: return "调整操作";
        case 14: return "磨损维修";
      }
    },
    getStatusColor(status) {
      // 根据不同的 status 返回对应的类名
      switch (status) {
        case 1:
          return "#52962A";
        case 2:
          return "#F29D38";
        case 3:
          return "#999999";
        default:
          return "#e14a3b";
      }
    },
    getTopBarStatusClass(status) {
      // 根据不同的 status 返回对应的类名
      switch (status) {
        case 1:
          return "info-bar";
        case 2:
          return "info-bar-orange";
        case 3:
          return "info-bar-gray";
        default:
          return "info-bar-red";
      }
    },
    getPercentageStatusClass(status) {
      // 根据不同的 status 返回对应的类名
      switch (status) {
        case 1:
          return "percentage"; // 默认类名
        case 2:
          return "percentage-orange";
        case 3:
          return "percentage-gray";
        default:
          return "percentage-red";
      }
    },
    calculateTimeDifference(time) {
      const timeDiffMilliseconds = Math.abs(new Date(time) - new Date());
      const hours = Math.floor(timeDiffMilliseconds / (1000 * 60 * 60));
      const minutes = Math.floor(
          (timeDiffMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
      );
      if (hours === 0) return `${minutes}分钟`;
      else return `${hours}小时${minutes}分钟`;
    },
    fetchData() {
        // status
        //   .getProductionStatus(
        //       this.page_size,
        //       this.current_page,
        //       this.workshop_value
        //   )
        //   .then((response) => {
        //     console.log(this.current_page)
        //     this.statusData = [];

        //     if (response.data.data !== null) {
        //       this.statusData = response.data.data;

        //       for (let i = 0; i < this.statusData.length; i++) {
        //         this.statusData[i].color = this.getStatusColor(
        //             this.statusData[i].machine_status
        //         );
        //         this.statusData[i].percentage_color =
        //             this.getPercentageStatusClass(
        //                 this.statusData[i].machine_status
        //             );
        //         this.statusData[i].bar_color = this.getTopBarStatusClass(
        //             this.statusData[i].machine_status
        //         );
        //       }
        //       this.dataStatusBackup = response.data.data;

        //       let task_list = Array.from(
        //           new Set(this.statusData.map((item) => item.task_id))
        //       ).sort();
        //       this.production_line_options = task_list.map((item) => {
        //         return {
        //           value: item,
        //           label: `Task-${item}`,
        //         };
        //       });
        //       this.production_line_options.unshift({
        //         value: -1,
        //         label: `全部`,
        //       });

        //       if (this.tempValue != -1) {
        //         this.statusData = this.dataStatusBackup.filter(
        //             (item) => item.task_id == this.tempValue
        //         );
        //       }
        //       this.total_page = this.statusData.length;
        //       this.tempStatusData = this.statusData.slice(
        //           (this.current_page - 1) * this.page_size,
        //           this.current_page * this.page_size
        //       );

        //     }
        //   })
        //   .catch((error) => {
        //     // 处理错误
        //     console.error(error);
        //   });

        status.getNewProductionStatus(this.workshop_value).then((response) => {
            console.log("----------------------")
            console.log(response.data.data)
            if (response.data.data !== null) {
                this.statusData = response.data.data;
                this.dataStatusBackup = response.data.data;
                let task_list = Array.from(
                    new Set(this.statusData.map((item) => item.task_id))
                ).sort();
                this.production_line_options = task_list.map((item) => {
                    return {
                        value: item,
                        label: `Task-${item}`,
                    };
                });
                this.production_line_options.unshift({
                    value: -1,
                    label: `全部`,
                });
                if (this.tempValue != -1) {
                    this.statusData = this.dataStatusBackup.filter(
                        (item) => item.task_id == this.tempValue
                    );
                }
                this.total_page = this.statusData.length;
                this.tempStatusData = this.statusData.slice(
                    (this.current_page - 1) * this.page_size,
                    this.current_page * this.page_size
                );
            }
        }).catch((error) => {
            // 处理错误
            console.error(error)
        });
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.fetchData();
    },
    getSheet_1_1(type){
        if( type == "车床" || type == "铣床")
            return "当前坐标";
        else( type == "M" || type == "T" ||type == "TT")
            return "绝对坐标";
    },
    getSheet_1_3(type){
        if( type == "车床" || type == "铣床")
            return "工作坐标";
        else( type == "M" || type == "T" ||type == "TT")
            return "相对坐标";
    },
    getRight_1(type){
        if( type == "车床" || type == "铣床")
            return "自动运转时间";
        else( type == "M" || type == "T" ||type == "TT")
            return "运行时间";
    },
    getRight_3(type){
        if( type == "车床" || type == "铣床")
            return "启动时间";
        else( type == "M" || type == "T" ||type == "TT")
            return "循环时间";
    },
  },
});
</script>

<style lang="scss">
@import "../../assets/scss/status.scss";
</style>
